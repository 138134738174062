import { FC } from "react";
import { Button, ButtonProps, Modal } from "semantic-ui-react";
import { styled } from "styled-components";

const StyledActions = styled(Modal.Actions)`
  text-align: center !important;

  & > .ui.button {
    margin: 0 !important;
    min-width: 6.5em;
  }
`;

export const CenteredOkButton: FC<ButtonProps> = (props) =>
  <StyledActions><Button {...props}>{ props.children || "OK" }</Button></StyledActions>;
