import { RouteComponentProps, useParams } from '@reach/router';
import { navigate } from 'gatsby';
import { FC, useState } from "react";
import { Button, Form, Icon, Input, Message } from "semantic-ui-react";
import { IHypertextStory, IStoryLine } from '../../story-api';
import Layout from "../layout/Layout";
import PageHeader from '../layout/PageHeader';
import PleaseWaitPage from "../layout/pleaseWaitPage";
import { useNotificationApi } from '../notifications/api';
import styled from 'styled-components';

// TODO: Now this is just copied from StoryLinesList.tsx
// TODO: It needs to be aligned with requirments of adding a new author
/* function createStoryLine(story: IHypertextStory | undefined, title: string): Promise<void> {
  if (!story) return Promise.reject();
  return new Promise((resolve, reject) => {
    story.createStoryLine(title).then((storyLine: IStoryLine) => {
      resolve();
      navigate(storyLine.editPath);
    }, (error) => {
      console.error("Error creating story line:", error);
      reject(error);
    });
  });
} */

export const StoryInvitations: FC<RouteComponentProps> = (_props: RouteComponentProps) => {
  // const { catchError } = useNotificationApi();

  // if (isLoading) return <PleaseWaitPage></PleaseWaitPage>;

  return <Layout>
    <PageHeader text="Zaproszenia do współautorstwa powieści" />
    <Invitation title="tytuł" owner="użytkownik" onChoice={() => {}} />
  </Layout>;
};

const Invitation: FC<{
  title: string,
  owner: string,
  onChoice: (title: string | null) => void, // non-empty string = accepted, null = rejected
}> = ({ title, owner, onChoice }) => {
  const [accepted, setAccepted] = useState(false);
  const [lineTitle, setLineTitle] = useState("");

  const header = <Message.Header>
    <q>{ title }</q>
  </Message.Header>;

  return <div>
    { accepted ? <Message attached>{ header }</Message> : <Message>
      <Flex>
        <div>
          { header }
          <p>Zaproszenie od: <b>{ owner }</b></p>
        </div>
        <Button negative onClick={() => onChoice(null)}>Odrzuć</Button>
        <Button positive onClick={() => setAccepted(true)}>Zaakceptuj</Button>
      </Flex>
    </Message> }
    { accepted && <Form className='attached fluid segment'>
      <Info>
        <Icon name="info circle" />
        <p>
          Dołączenie do powieści kolaboratywnej wiąże się z utworzeniem dla Ciebie osobnej linii fabularnej.
          <br />Tylko Ty oraz opiekun powieści możecie ją edytować.
          <br />Ty i inni autorzy możecie jednak wzajemnie linkować do segmentów w innych liniach fabularnych.
        </p>
      </Info>
      <FlexInput>
        <label>Nadaj tytuł swojej linii fabularnej:</label>
        <div className="ui action input">
          <input type="text" autoFocus value={lineTitle} onChange={e => setLineTitle(e.target.value)} />
          <Button positive disabled={!lineTitle} onClick={() => onChoice(lineTitle)}>Utwórz</Button>
        </div>
      </FlexInput>
    </Form> }
  </div>;
};

const Flex = styled(Message.Content)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > :first-child {
    flex-grow: 1;
  }
`;

const FlexInput = styled.div.attrs({ className: "field" })`
  display: flex;
  align-items: center;

  > label {
    white-space: nowrap;
    margin: 0 !important;
  }

  > .ui.input {
    margin: 0 0 0 .8em !important;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 1.5em;
  width: max-content;
  color: #0e2d6d;

  .icon {
    font-size: 3em;
    float: left;
  }

  p {
    font-style: italic;
    margin: 0 0 0 .7em !important;
    font-size: 93%;
  }
`;
