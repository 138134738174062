import { Component, createRef, RefObject } from 'react';

export interface ResizeDetectorProps {
  as?: string;
  ref?: never;
  onResize: (width: number, height: number, elem: HTMLElement) => void;
}
export class ResizeDetector extends Component<Record<string, any> & ResizeDetectorProps> {
  resizeObserver: ResizeObserver | null = null;
  ref: RefObject<any> = createRef();

  componentDidMount(): void {
    if (window?.ResizeObserver === undefined) return;
    const elem = this.ref.current;
    if (!elem) {
      console.error("ResizeDetector: ref.current is", elem, "but should be an HTMLElement");
      return;
    }
    this.resizeObserver = new ResizeObserver((entries) => {
      const size = entries[0].borderBoxSize[0];
      this.props.onResize(size.inlineSize, size.blockSize, elem);
    });
    this.resizeObserver.observe(elem, { box: "border-box" });
    this.props.onResize(elem.offsetWidth, elem.offsetHeight, elem);
  }

  componentWillUnmount(): void {
    if (this.resizeObserver) this.resizeObserver.disconnect();
  }

  render(): JSX.Element {
    const { as, ...props } = this.props;
    const Tag: any = as || "div";
    return <Tag {...props} ref={this.ref} />;
  }
}
