import { RouteComponentProps, useParams } from '@reach/router';
import { all as getLanguages } from 'langs';
import { FC, useEffect, useState } from 'react';
import { Form, Grid, Header } from "semantic-ui-react";
import styled from 'styled-components';
import { useSaveStoryMetadata } from '../../mutations';
import { useStoryMetadata } from "../../queries";
import { ForgivenessNames, GenreNames, StoryMetadata } from '../../story-api/metadata';
import Layout from "../layout/Layout";
import PageHeader from '../layout/PageHeader';
import PleaseWaitPage from "../layout/pleaseWaitPage";
import { ProgressButton } from '../misc/progressButton';

const METADATA: ReadonlyArray<[string, string, string, string | object]> = Object.freeze([
  ["bibliographic", "title", "Tytuł", "text!"],
  ["bibliographic", "headline", "Podtytuł", "text"],
  ["bibliographic", "author", "Autor", "text!"],
  ["bibliographic", "description", "Opis", "textarea"],
  ["bibliographic", "genre", "Gatunek", GenreNames],
  ["bibliographic", "language", "Język", "lang"],
  ["bibliographic", "group", "Zbiór", "text"],
  ["bibliographic", "series", "Seria", "text"],
  ["bibliographic", "seriesnumber", "Numer w serii", "uint"],
  ["bibliographic", "forgiveness", "Trudność", "forgiveness"],
]);

const langs = getLanguages();

type MetadataForm = ReadonlyArray<{
  section: string;
  tag: string;
  label: string;
  type: string | object;
  value: string;
  required: boolean;
}>;

function buildForm(metadata: StoryMetadata): MetadataForm {
  return METADATA.map(([section, tag, label, type]) => {
    const required = typeof type === "string" && type.endsWith("!");
    if (required) type = (type as string).slice(0, -1);

    const element = metadata.queryTagName(section, tag);
    const value = element?.textContent || "";

    return {section, tag, label, type, value, required};
  });
}

function saveForm(metadata: StoryMetadata, form: MetadataForm): StoryMetadata {
  metadata = metadata.clone();
  for (const { section, tag, value } of form) {
    if (value) {
      metadata.getOrCreate(section, tag).textContent = value;
    } else {
      const element = metadata.queryTagName(section, tag, false);
      if (element) element.textContent = "";
    }
  }
  return metadata;
}

const StoryMeta = (_props: RouteComponentProps) => {
  const { story: path } = useParams();
  if (!path ||  typeof document === "undefined") return <PleaseWaitPage></PleaseWaitPage>;
  const { data, isLoading, isError, isSuccess, error } = useStoryMetadata(path);
  const { mutate: saveMetadata, isLoading: isSaving } = useSaveStoryMetadata();

  const [ metadata, setMetadata ] = useState<MetadataForm | null>(null);
  useEffect(() => {
    const m = isSuccess && data?.metadata;
    setMetadata(m ? buildForm(m) : null);
  }, [path, isSuccess]);

  if (isLoading) return <PleaseWaitPage></PleaseWaitPage>;

  const form = metadata ? metadata.map((row) => {
    const onChange = (e: any) => {
      row.value = e.target.value || "";
      setMetadata([...metadata]);
    };
    const {section, tag, label, type, value, required} = row;
    const props = { value, required, onChange };

    let field: JSX.Element;
    switch (type) {
      case "text":
        // Text field
        field = <input type="text" {...props} />;
        break;

      case "uint":
        // Number field
        field = <input type="number" {...props} min="1" />;
        break;

      case "textarea":
        // Textarea
        field = <textarea {...props} />;
        break;

      case "lang":
        // Language dropdown
        const lang = langs.find((lang: any) => Object.values(lang).includes(value));
        field = <select {...props}>
          { lang ? null : <InvalidOption>{value}</InvalidOption> }
          <optgroup label="Podstawowe">
            <option key="pl" value="pl">Polski (pl)</option>
            <option key="en" value="en">Angielski (en)</option>
          </optgroup>
          <OtherLangOptgroup label="Inne języki świata (ISO 639-1)">
            { langs.map((lang: any) => {
              const code = lang["1"];
              if (code == "pl" || code == "en") return null;
              return <option key={code} value={code}>{code} ({lang.local})</option>;
            }) }
          </OtherLangOptgroup>
        </select>;
        break;

      case "forgiveness":
        // Forgiveness dropdown
        field = <select {...props}>
          <optgroup label="Nieokreślona">
            { ForgivenessNames.hasOwnProperty(value) ? null : <InvalidOption>{value}</InvalidOption> }
            <option key="" value="">{ForgivenessNames[""]}</option>
          </optgroup>
          <optgroup label="Określona">{
            Object
              .entries(ForgivenessNames)
              .filter((entry) => entry[0] !== "")
              .map(([value, label]) => <option key={value} value={value}>{label}</option>)
          }</optgroup>
        </select>;
        break;

      default:
        // Enum dropdown
        if (typeof type !== "object") throw new Error(`Unknown metadata type: ${type}`);
        let entries: [string, string][];
        if (type.hasOwnProperty("0") && (type as any)[(type as any)["0"]] === 0) {  // TS enum
          entries = Object.values(type).filter((v) => typeof v === "string").map((v) => [v, v]);
        } else {  // (str -> str) object
          entries = Object.entries(type).sort((a, b) => a[1].localeCompare(b[1]));
        }
        field = <select {...props}>
          { type.hasOwnProperty(value) ? null : <InvalidOption>{value}</InvalidOption> }
          { entries.map(([value, label]) => <option key={value} value={value}>{label}</option>) }
        </select>;
    }

    return <Form.Field key={section + "." + tag} as={StyledFormRow}>
      {type === "textarea" ? <TextAreaLabel>{label}</TextAreaLabel> : <b>{label}</b>}
      {field}
    </Form.Field>;
  }) : null;

  const save = () => {
    if (!metadata || !data?.metadata) {
      console.error("No metadata to save");
      return;
    }
    const newMetadata = saveForm(data.metadata, metadata);
    saveMetadata({ metadata: newMetadata });
  };

  return <Layout>
    <PageHeader title={data?.story?.title} text="metadane powieści" />
    {isError ? <div>Nie udało się pobrać danych powieści. { error + "" }</div> : null}
    {form && data?.metadata ? <Grid>
      <Grid.Column width={11}>
        <Form>
          {form}
          <Form.Field as={StyledFormRow}>
            <br />
            <ProgressButton positive onClick={save} disabled={isSaving} style={{margin: 0}}>
              Zapisz
            </ProgressButton>
          </Form.Field>
        </Form>
      </Grid.Column>
      <StyledRightColumn width={5}>
        <Header as="h3">Metadane nieedytowalne</Header>
        <StoryInfo metadata={data.metadata.metadata}></StoryInfo>
      </StyledRightColumn>
    </Grid> : null}
  </Layout>;
};

const StoryInfo: FC<{ metadata: [string, string][] }> = ({ metadata }) => {
  return <StyledDefinitionList>{
    metadata.map(([label, value]) => [
      <dt key={label + "k"}>{label}</dt>,
      <dd key={label + "v"}>{
        // use <code> for IDs
        /^(?:IFID|TUID|BAFN)/.exec(label) ? <code>{value}</code> : value
      }</dd>
    ])
  }</StyledDefinitionList>
};

const StyledRightColumn = styled(Grid.Column)`
  border-left: 1px solid rgba(34,36,38,.15);
`;

const StyledDefinitionList = styled.dl`
  dt {
    font-weight: bold;
  }
  dd {
    margin: 0 0 1em 0;
  }
  code {
    font-family: "Chivo Mono", monospace;
    font-size: 93%;
    color: #0e2d6d;
  }
`;

const StyledFormRow = styled.label`
  display: grid;
  grid-template-columns: 3fr 16fr;
  align-items: center;
  text-align: right;
  grid-gap: 1em;
`;

const TextAreaLabel = styled.b`
  align-self: start;
  padding-top: 0.5em;
`;

const OtherLangOptgroup = styled.optgroup`
  option {
    font-family: monospace;
  }
`;

const InvalidOption = styled.option.attrs<{ children: string }>(props => ({
  disabled: true,
  value: props.children
}))`
  display: none;
`;

export default StoryMeta;
