import { IStoryLine } from "../../story-api";

export default function CollectionImage(props: {
  collection: IStoryLine;
  width: number;
  height: number;
}) {
  return <img
    src={`https://picsum.photos/seed/${props.collection.hashCode()}/${props.width}/${props.height}`}
    alt=""
    width={props.width}
    height={props.height}
  />;
}
