import { Button } from "semantic-ui-react";
import { keyframes, styled } from "styled-components";

const progressButtonAnimation = keyframes`
  0% { 
    background-size: 0 100%;
  }
  90% {
    background-size: 100% 100%;
  }
`;

const _ProgressButton = styled(Button)`
  position: relative;

  &.ui.disabled.button {
    opacity: .75 !important;
    background-image: linear-gradient(to right, #ffffff4c, #ffffff4c);
    background-repeat: no-repeat;
    animation: ${progressButtonAnimation} 2s ease infinite;
  }
`;

export const ProgressButton = (props: any) => {
  if (props.hasOwnProperty("as")) {
    props = {...props, forwardedAs: props.as};
    delete props.as;
  }
  return <_ProgressButton {...props} />;
}
