import { FC, useState } from "react";
import { Button, ButtonProps, Form, Input, Modal } from "semantic-ui-react";
import { ProgressButton } from "../misc/progressButton";

export const NewStoryModal: FC<ButtonProps & {
  header: string;
  onSubmit: (title: string) => Promise<unknown>;
}> = (props) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [disabled, setDisabled] = useState(false);

  const {header, onSubmit, ...otherProps} = props;

  const submit = () => {
    if (title.trim() === "") return;
    setOpen(false);
    setDisabled(true);

    onSubmit(title).finally(() => {
      setDisabled(false);
    });
  };

  return <Modal
    trigger={<ProgressButton {...otherProps} disabled={disabled} />}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    open={open}
    size="tiny"
  >
    <Modal.Header>{header}</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <Form onSubmit={submit}>
          <Form.Field>
            <Input placeholder="Wpisz tytuł" autoFocus value={title} onChange={e => setTitle(e.target.value)} />
          </Form.Field>
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={() => setOpen(false)}>Anuluj</Button>
      <Button positive onClick={submit} disabled={title.trim() === ""}>Utwórz</Button>
    </Modal.Actions>
  </Modal>;
};
