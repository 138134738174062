import { RouteComponentProps, useParams } from '@reach/router';
import { FC, useState } from "react";
import { Icon, Label, Message, Table } from "semantic-ui-react";
import styled from 'styled-components';
import { useRemoveAuthorFromStory } from '../../mutations';
import { useStoryLineList } from "../../queries";
import { IStoryLine } from '../../story-api';
import Layout from "../layout/Layout";
import PageHeader from '../layout/PageHeader';
import { Confirm } from '../misc/confirmation';
import PleaseWaitPage from "../layout/pleaseWaitPage";
import { NewLineModal } from './NewLineModal';

function formatAuthor(lines: IStoryLine[]): string {
  const authors = new Set<string>();
  for (const line of lines) {
    if (line.author) {
      authors.add(line.author);
    }
  }
  return Array.from(authors).sort().join(", ");
}

export const StoryAuthors: FC<RouteComponentProps> = (_props: RouteComponentProps) => {
  const { story: path } = useParams();
  const { data, isLoading } = useStoryLineList(path);
  const { mutate: removeAuthor, isLoading: isRemovalInProgress } = useRemoveAuthorFromStory();
  const [toDelete, setToDelete] = useState<IStoryLine | null>(null);
  const [isOwnerChanging, setIsOwnerChanging] = useState(false);

  if (isLoading) return <PleaseWaitPage></PleaseWaitPage>;

  const owners = new Map<string, IStoryLine[]>();
  let storyOwner: string | null = null;
  if (data && data.storyLines) {
    for (const line of data.storyLines) {
      const owner = owners.get(line.owner);
      if (owner === undefined) {
        owners.set(line.owner, [line]);
      } else {
        owner.push(line);
      }
      if (line.url.pathname.match(/^\/~[^/]+\/?$/)) {
        storyOwner = line.owner;
      }
    }
  }

  const orphaned = owners.get("");
  owners.delete("");

  const isOwner: boolean = data?.story?.isOwner ?? false;

  const changeOwner = () => {
    setIsOwnerChanging(false);
    alert("Not implemented");
  };
  const deletionConfirmed = () => {
    removeAuthor(toDelete!);
    setToDelete(null);
  };

  return <Layout>
    <PageHeader title={data?.story?.title} text="autorzy">{ isOwner && data?.story ? <>
      <NewLineModal positive story={data.story}>
        <Icon name="plus" />
        Dodaj współautora
      </NewLineModal>
    </> : null }</PageHeader>
    { owners.size > 0 ? <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nazwa użytkownika</Table.HeaderCell>
          <Table.HeaderCell>Nazwisko / pseudonim(y)</Table.HeaderCell>
          <Table.HeaderCell>Przypisane linie fabularne</Table.HeaderCell>
          <Table.HeaderCell>Rola</Table.HeaderCell>
          {isOwner && <Table.HeaderCell textAlign='right'>Usuń</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { Array.from(owners.entries()).map(([owner, lines]) => <Table.Row key={owner}>
          <Table.Cell>{owner}</Table.Cell>
          <Table.Cell>{formatAuthor(lines)}</Table.Cell>
          <Table.Cell>{lines.map(l => l.title).join(", ")}</Table.Cell>
          <Table.Cell>
            <Label size="tiny" basic color="pink">
              {
                storyOwner === owner ? <><Icon name={"user"} /> właściciel</> :
                <><Icon name={"feather alternative" as any} /> autor</>
              }
            </Label>
          </Table.Cell>
          {isOwner && <StyledActionCell textAlign='right'>
            { isRemovalInProgress ?
              <Icon name="spinner" loading /> : storyOwner === owner ?
              <Icon name={"people arrows" as any} title="Zmień właściciela hipertekstu" tabIndex="0"
                onClick={() => setIsOwnerChanging(true)} /> :
              <Icon name="times" title="Usuń autora" tabIndex="0"
                onClick={() => setToDelete(lines[0])} /> }
          </StyledActionCell> }
        </Table.Row>) }
      </Table.Body>
    </Table> : orphaned ? <Message negative icon>
      <Icon name={"skull crossbones" as any} size="huge" />
      <Message.Content>
        <Message.Header>Powieść osierocona</Message.Header>
        <p>Żadna linia fabularna nie ma przypisanego autora z kontem w systemie.<br />
          Skontaktuj się z administratorem, aby przypisać opiekuna do powieści.</p>
      </Message.Content>
    </Message> : <div>Nie udało się pobrać listy autorów.</div> }
    { orphaned && <div>
      <h3>Osierocone linie fabularne</h3>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Tytuł</Table.HeaderCell>
            <Table.HeaderCell>
              Autor
              <HeaderComment>
                (nawet jeśli podany, nie jest powiązany z żadnym kontem użytkownika w systemie)
              </HeaderComment>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { orphaned.map(line => <Table.Row key={line.url.href}>
            <Table.Cell>{line.title}</Table.Cell>
            <Table.Cell>{line.author}</Table.Cell>
          </Table.Row>) }
        </Table.Body>
      </Table>
    </div> }
      <Confirm
        negative
        header="Potwierdź wykluczenie z zespołu"
        confirmButton="Usuń"
        cancelButton="Anuluj"
        open={toDelete != null}
        onCancel={() => setToDelete(null)}
        onConfirm={deletionConfirmed}>
          <p>Czy na pewno chcesz wykluczyć tego użytkownika z zespołu?</p>
          <p style={{textIndent: "2em"}}>
            <b>{toDelete?.author}</b>&emsp;(uid: <i>{toDelete?.owner}</i>)
          </p>
          <p>Użytkownik nie będzie mógł już edytować żadnych linii fabularnych,
            ale&nbsp;treści które utworzył nie zostaną usunięte.</p>
      </Confirm>
      <Confirm
        header="Zmiana właściciela hipertekstu"
        open={isOwnerChanging}
        onCancel={() => setIsOwnerChanging(false)}
        onConfirm={changeOwner}>
          <p>Wskaż nowego właściciela:</p>
          <p style={{textIndent: "2em"}}>TODO: select</p>
      </Confirm>
  </Layout>;
};

const StyledActionCell = styled(Table.Cell)`
  .icon {
    cursor: pointer;
  }
`;

const HeaderComment = styled.i`
  font-weight: normal;
  margin-left: .5em;
  font-size: .8em;
  vertical-align: top;
`;
