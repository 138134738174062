import { Router } from "@reach/router";
import { StoryAuthors } from "../components/stories/StoryAuthors";
import { StoryInvitations } from "../components/stories/StoryInvitations";
import StoryLinesList from "../components/stories/StoryLinesList";
import StoryList from "../components/stories/StoryList";
import StoryMeta from "../components/stories/StoryMeta";
import StoryResearch from "../components/stories/StoryResearch";

export default function StoryListPage() {
  return <Router>
    <StoryMeta path="/o/:story/meta" />
    <StoryResearch path="/o/:story/badacz" />
    <StoryLinesList path="/o/:story" />
    <StoryAuthors path="/o/:story/autorzy" />
    <StoryInvitations path="/o/zaproszenia" />
    <StoryList path="/o/" />
  </Router>;
}
