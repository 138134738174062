import { FC, useState } from "react";
import { ButtonProps, Header, Icon, Modal } from "semantic-ui-react";
import { storyApi } from "../../story-api";
import { CenteredOkButton } from "../misc/centeredOkButton";
import { ProgressButton } from "../misc/progressButton";
import { navigate } from "gatsby-link";
import { styled } from "styled-components";

async function doImport(files: FileList | null, setMessage: (s: string) => void): Promise<boolean> {
  if (!(files && files.length > 0)) return true;

  try {
    const status = await storyApi.importStory(files);
    if (status.finished) {
      // Upload successful, import finished immediately.
      navigate(`/e/${status.name}`);
    } else {
      // Upload successful, import enqueued to be finished asynchronously.
      setMessage(`Przesłano pliki do zaimportowania.\nIdentyfikator procesu importu: ${status.id}.`);
    }
    return false;
  } catch (error) {
    // Upload failed.
    setMessage((error + "") || "Nieznany błąd.");
    return true;
  }
}

export const ImportButton: FC<ButtonProps> = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState(null as string | null);
  const [isError, setIsError] = useState(false);

  return <ProgressButton as="label" disabled={disabled} {...props}>
    {props.children}
    <input type="file" style={{display: "none"}} multiple onChange={async (e) => {
      setDisabled(true);
      setIsError(await doImport(e.target.files, setMessage));
      setDisabled(false);
    }} />
    <Modal basic size="mini" open={message != null} onClose={() => setMessage(null)}>
      <Header icon>
        <Icon name={isError ? "exclamation triangle" : "info circle"} />
        {isError ? "Error importing story" : null}
      </Header>
      <StyledContent>{message}</StyledContent>
      <CenteredOkButton
        positive={!isError}
        negative={isError}
        onClick={() => setMessage(null)}
      />
    </Modal>
  </ProgressButton>;
};

const StyledContent = styled(Modal.Content)`
  white-space: pre-wrap;
  text-align: center;
`;
