import { RouteComponentProps, useParams } from '@reach/router';
import { FC, ReactNode } from 'react';
import { Header, Segment } from "semantic-ui-react";
import styled from 'styled-components';
import { useStoryMetadata } from "../../queries";
import { StoryMetadata } from '../../story-api/metadata';
import Layout from "../layout/Layout";
import PageHeader from '../layout/PageHeader';
import PleaseWaitPage from "../layout/pleaseWaitPage";

const StoryResearch = (_props: RouteComponentProps) => {
  const { story: path } = useParams();
  if (!path ||  typeof document === "undefined") return <PleaseWaitPage></PleaseWaitPage>;
  const { data, isLoading, isError, isSuccess, error } = useStoryMetadata(path);

  if (isLoading) return <PleaseWaitPage></PleaseWaitPage>;

  return <Layout>
    <PageHeader title={data?.story?.title} text="strefa badacza" />
    {isError ? <div>
      Nie udało się pobrać danych powieści. { error + "" }
    </div> : null}
    {isSuccess ? <StoryInfo metadata={data.metadata} />  : null}
  </Layout>;
};

const StoryInfo: FC<{metadata: StoryMetadata}> = ({ metadata }) => {
  return <>
    <StoryMetadataSegment metadata={metadata} />
    <Header as="h3">Informacje do uwzględnienia:</Header>
    <ul>
      <li>Struktura zespołu</li>
      <li>Liczba wejść (i wyjść?) czytelników</li>
      <li>Statystyki hipertekstu: liczba leksji, liczba linków, rodzaje linków</li>
      <li>Frekwencja słów</li>
    </ul>
    <Header as="h3">Arkusze do pobrania:</Header>
    <ul>
      <li>Rejestr przeglądania przez autorów linii fabularnych (z informacją, które są cudze)</li>
      <li>Rejestr tworzenia linków</li>
      <li>Rejestr czasu czytania całości (+ wykresy)</li>
      <li>Rejestr czasu czytania poszczególnych leksji</li>
    </ul>
    <Header as="h3">Statystyki grafu hiperłączy</Header>
    <ul>
      <li>Średnia liczba linków na leksję</li>
      <li><code>modularity</code> (???) (modularność)</li>
      <li><code>nx.betweenness_centrality(G)</code> (relacje m-dzy linkami a węzłami)</li>
      <li><code>nx.closeness_centrality(G)</code> (centralność bliskości)</li>
      <li><code>nx.degree_centrality(G)</code> (centralność stopnia)</li>
    </ul>
  </>;
};

const StoryMetadataSegment: FC<{metadata: StoryMetadata}> = ({ metadata }) => {
  let items = new Map<string, ReactNode[]>();

  [
    ["Tytuł:", metadata.queryText("bibliographic", "title")],
    ["Autor:", metadata.queryText("bibliographic", "author")],
    ...metadata.metadata
  ].forEach(([label, value]) => {
    if (value) {
      const current = items.get(label) || [];
      if (current.length > 0) current.push(<br />);
      current.push(value);
      items.set(label, current);
    }
  });

  return <Segment floated='right'>
    <Header as="h3">Skrócone metadane</Header>
    <StyledDefinitionList>{
      Array.from(items.entries()).map(([label, value]) => value && <>
        <dt>{label}</dt>
        <dd>{value}</dd>
      </>)
    }</StyledDefinitionList>
  </Segment>;
};

const StyledDefinitionList = styled.dl`
  dt {
    font-weight: bold;
  }
  dd {
    margin: 0 0 1em 0;
  }
`;

export default StoryResearch;
