import { navigate } from "gatsby";
import { FC, useState } from "react";
import { Button, ButtonProps, Form, Input, List, Modal, Segment, StrictListItemProps } from "semantic-ui-react";
import styled from "styled-components";
import { IHypertextStory, IStoryLine } from "../../story-api";
import { AuthorIcon } from "../misc/authorIcon";
import { ProgressButton } from "../misc/progressButton";
import { useNotificationApi } from "../notifications/api";
import { useUserList } from "../../queries";
import { User } from "../../user";
import { queryClient } from "../../RootElement";

// TODO: Now this is just copied from StoryLinesList.tsx
// TODO: It needs to be aligned with requirments of adding a new author
function createStoryLine(
  story: IHypertextStory,
  title: string,
  author?: string | undefined,
): Promise<void> {
  return new Promise((resolve, reject) => {
    story.createStoryLine(title, author).then((storyLine: IStoryLine) => {
      queryClient.invalidateQueries(["story", "lines", story.id]);
      resolve();
      if (!author) navigate(storyLine.editPath);
    }, (error) => {
      console.error("Error creating story line:", error);
      reject(error);
    });
  });
}

export const NewLineModal: FC<ButtonProps & {
  story: IHypertextStory;
  inviteMode?: boolean;
}> = ({story, ...props}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState(story.owner);
  const [disabled, setDisabled] = useState(false);
  const { catchError } = useNotificationApi();
  const { data: rawUserList } = useUserList();

  const openModal = () => {
    setTitle("");
    setAuthor(story.owner);
    setOpen(true);
  };

  const dismiss = () => {
    setOpen(false);
  };

  const submit = () => {
    if (title.trim() === "") return;
    dismiss();
    setDisabled(true);

    catchError(
      "Nie udało się utworzyć linii fabularnej",
      createStoryLine(story, title, author === story.owner ? undefined : author)
    ).finally(() => {
      setDisabled(false);
    });
  };

  const self = new User({
    username: story.owner,
    displayName: story.author,
  });
  const users = rawUserList ? rawUserList.filter(user => user.username !== self.username) : [];
  users.unshift(self);

  return <Modal
    trigger={<ProgressButton {...props} disabled={disabled} />}
    onOpen={openModal}
    onClose={() => setOpen(false)}
    open={open}
    size="tiny"
  >
    <Modal.Header>Nowa linia fabularna</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <Form onSubmit={submit}>
          <Form.Field>
            <label>Tytuł nowej linii fabularnej:</label>
            <Input placeholder="Wpisz tytuł" autoFocus value={title} onChange={e => setTitle(e.target.value)} />
          </Form.Field>
          <AuthorListField>
            <label>Autor:</label>
            <Segment>
              <List horizontal selection><br />
                { users.map(user =>
                  <UserBox key={user.username} user={user} active={user.username === author}
                    onClick={() => setAuthor(user.username)} />) }
              <br /></List>
            </Segment>
          </AuthorListField>
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={dismiss}>Anuluj</Button>
      <Button positive onClick={submit} disabled={title.trim() === ""}>Utwórz</Button>
    </Modal.Actions>
  </Modal>;
};

const UserBox: FC<{ user: {
  displayName: string;
  toString: () => string;
} } & StrictListItemProps> = ({ user, ...props }) => {
  return <List.Item as="button" type="button" {...props}>
    <AuthorIcon author={user.displayName}></AuthorIcon>
    <List.Content>
      <List.Header>{user + ""}</List.Header>
    </List.Content>
  </List.Item>;
};

const AuthorListField = styled(Form.Field)`
  .ui.attached.segment {
    margin: 0;
    width: 100%;
    border-color: rgba(34, 36, 38, .15);
  }

  .ui.selection.list > .item {
    margin: 1px;
    border: 1px solid transparent;
    transition: border-color .1s ease;

    &:hover, &:focus {
      border-color: #ccc;
      outline: none;
    }

    &.active {
      border-color: #bbb;
    }

    .content, .header {
      color: inherit;
    }
  }
`;
