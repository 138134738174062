import Layout from "./Layout";

const PleaseWaitPage = () => {
  return (
    <Layout>
      <div style={{ padding: "30px 0 50px" }}>
        <div className="ui active inverted dimmer">
          <div className="ui text loader">Loading</div>
        </div>
      </div>
    </Layout>
  );
};

export default PleaseWaitPage;
