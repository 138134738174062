import { Link } from "gatsby";
import { FC, useEffect, useRef } from 'react';
import { Button, Header, Icon, Label } from "semantic-ui-react";
import styled from "styled-components";
import { useSaveCover } from "../../mutations";
import { useStoryMetadata } from "../../queries";
import { useLastEditedUrl } from "../../services/lastEdited";
import { IHypertextStory } from "../../story-api";
import { ResizeDetector } from "../misc/resizeDetector";
import StorySegment from "./StorySegment";

type StoryDetailsProps = {
  story: IHypertextStory;
};
export const StoryDetails: FC<StoryDetailsProps> = ({ story }) => {
  const lineUrl = story.editPath.replace("/e/", "/o/");
  const authorsUrl = lineUrl + "autorzy/";
  const metaUrl = lineUrl + "meta/";
  const researchUrl = lineUrl + "badacz/";

  const roles: [string, string][] = [];
  if (story.isOwner) roles.push(["właściciel", "user"]);
  if (story.isResearcher) roles.push(["badacz", "microscope"]);
  if (roles.length < 1) roles.push(["autor", "feather alternative"]);

  const lastEdited: string | undefined = useLastEditedUrl(story.url);
  const lastEditedUrl = lastEdited ? story.editPath + lastEdited : null;

  const { mutate: saveCover, isLoading } = useSaveCover();
  const { data } = useStoryMetadata(story);
  const description = data?.metadata.description || "";

  const uploadCoverImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    saveCover({story, file});
  };

  const resize = (_width: unknown, height: number, elem: HTMLElement) => {
    let sum = 0;
    for (let child = elem.firstElementChild; child; child = child.nextElementSibling) {
      if (child instanceof HTMLElement) {
        sum += child.offsetHeight;
      }
    }
    elem.classList.toggle("inkah-expanding", sum > height);
  };

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (description && ref.current) {
      const elem = ref.current.lastElementChild as HTMLElement;
      resize(0, elem.offsetHeight, elem);
    }
  }, [ref.current, description]);

  return (
    <Container ref={ref}>
      <StorySegment isLoading={isLoading} story={story} to={lastEditedUrl} ribbon={
        lastEditedUrl ? <Label ribbon color="black" size="tiny">
          Kontynuuj pisanie: {lastEdited}
        </Label> : null
      }>
        <Label as={CornerLabel} corner="right" color="blue" size="tiny">
          <Icon name="image" />
          <input type="file" hidden onChange={uploadCoverImage} />
        </Label>
      </StorySegment>
      <DetailsContainer as={ResizeDetector} className="ui segment" onResize={resize}>
        <div>
          {story.isResearcher && <Button floated="right" icon="microscope" compact secondary
            content="Strefa badacza" as={Link} to={researchUrl} />}
          <Header as={StyledHeader}>
            <q>{story.title}</q>
            {story.author && [", ", <i key={1}>{story.author}</i>]}
            {roles.map(([role, icon], i) =>
              <Label key={i} size="tiny" basic color="pink">
                rola: <Icon name={icon as any} /> {role}
              </Label>
            )}
          </Header>
          {description && <p>{description}</p>}
        </div>
        <ActionRow>
          <Button as={Link} to={lineUrl} icon="stream" content="Linie fabularne" compact
            primary={!lastEdited && (!story.isResearcher || story.isOwner)} />
          <Button as={Link} to={authorsUrl} icon="user friends" content="Autorzy" compact />
          <Button as={Link} to={metaUrl} icon="feather alternative" content="Metadane" compact />
        </ActionRow>
      </DetailsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  margin: 2px 0 4px;

  .ui.segment
`;

const DetailsContainer = styled.div`
  margin-top: 0 !important;
  flex: 1;
  height: 212px;
  position: relative;
  overflow: hidden;

  &.inkah-expanding {
    p {
      padding: 3px 7px 7px;
      margin: -4px -8px -8px;
      background: #fff;
      border: 1px solid transparent;
      border-radius: .286rem;
      position: relative;
    }

    &:hover {
      overflow: visible;

      p {
        border-color: rgba(34,36,38,.15);
        z-index: 4;
      }
    }
  }
`;

const ActionRow = styled.div`
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 1rem;
  right: 1rem;
  padding: 1.5rem 0 1rem;

  .inkah-expanding & {
    background: linear-gradient(#fff0 0, #fffc 0.5rem, #ffff 1rem);
  }
`;

const CornerLabel = styled.label`
  opacity: 0;
  transition: 0.3s opacity ease-in-out !important;

  :hover > &, &:focus {
    opacity: 1;
  }
`;

const StyledHeader = styled.h3`
  margin-bottom: 1rem !important;
  
  &.ui.header .ui.label {
    margin: 0 0 0 0.4em;
    vertical-align: top;

    &:first-of-type {
      margin-left: 1rem;
    }

    & > .icon {
      margin: 0 .1em 0 .25em;
    }
  }
`;

export default StoryDetails;
