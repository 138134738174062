import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import { FC, useCallback, useEffect, useState } from "react";
import { Input, List } from "semantic-ui-react";
import styled from "styled-components";
import { useStoryList } from "../../queries";
import { IHypertextStory, storyApi } from "../../story-api";
import Layout from "../layout/Layout";
import PageHeader from "../layout/PageHeader";
import PleaseWaitComponent from '../misc/pleaseWaitComponent';
import { useNotificationApi } from '../notifications/api';
import { ImportButton } from "./ImportButton";
import { NewStoryModal } from "./NewStoryModal";
import StoryDetails from "./StoryDetails";

function createStory(title: string): Promise<void> {
  return new Promise((resolve, reject) => {
    storyApi.createStory(title).then((story: IHypertextStory) => {
      resolve();
      navigate(story.editPath);
    }, (error) => {
      console.error("Error creating story:", error);
      reject(error);
    });
  });
}

const StoryList: FC<RouteComponentProps> = (_props: RouteComponentProps) => {
  const { data: storyList, isLoading, isError, isSuccess } = useStoryList();

  const [storySearchPhrase, setStorySearchPhrase] = useState("");
  const [filteredStoryList, setFilteredStoryList] = useState<IHypertextStory[]>([]);
  const { catchError } = useNotificationApi();

  useEffect(() => {
    const phrase = storySearchPhrase.toLocaleLowerCase();
    setFilteredStoryList(
      storyList?.filter((story: IHypertextStory) => story.matches(phrase)) || []
    );
  }, [storyList, storySearchPhrase]);

  const createStoryCatchError = useCallback((title: string): Promise<unknown> => {
    return catchError("Nie udało się utworzyć hipertekstu", createStory(title))
  }, [catchError]);

  return (
    <Layout>
      <PageHeader text="Twoje hiperteksty" equal>
        <Input
          as={SearchInput}
          label={"Wyszukaj hipertekst"}
          value={storySearchPhrase}
          placeholder="Nazwa lub autor"
          onChange={(_, data) => setStorySearchPhrase(data.value)}
        />
        <NewStoryModal positive onSubmit={createStoryCatchError}
          header="Podaj tytuł nowego hipertekstu">Utwórz</NewStoryModal>
        <ImportButton primary>Importuj</ImportButton>
      </PageHeader>
      <List>
        {isLoading ? <PleaseWaitComponent /> : null}
        {isError ? (
          <div>
            Nie udało się pobrać hipertekstów. Spróbuj ponownie za chwilę.
          </div>
        ) : null}
        {isSuccess
          ? filteredStoryList.map((story) => (
              <List.Item as={FlexContainer} key={story.url.href}>
                <StoryDetails story={story} />
              </List.Item>
            ))
          : null}
      </List>
    </Layout>
  );
};

const FlexContainer = styled.div`
  display: flex !important;
`;

const SearchInput = styled.label`
  width: auto !important;
  margin-right: 0.25em;

  &.ui.input > input:not([type="color"]) {
    padding: .607em 1em;
  }
`;

export default StoryList;
